import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header"href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/cookie-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/cookie-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-cookies/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politicia-de-cookies/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/cookie-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/cookie-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h1>POLÍTICA DE COOKIES</h1>
                <p>
                  Esta Política de Cookies se ha diseñado para informarle sobre
                  nuestras prácticas relativas a la recogida de información
                  mediante cookies y otras tecnologías de rastreo (como gifts,
                  web beacons, etc.).
                </p>
                <p>
                  Las funcionalidades para las que utilizamos estas tecnologías
                  pueden incluir lo siguiente:
                </p>
                <ul>
                  <li>Asistirle durante la navegación en la web </li>
                  <li>
                    Asistirle para registrarse en nuestros eventos, crear su
                    perfil y facilitar que nos ofrezca su valoración{" "}
                  </li>
                  <li>
                    Analizar el uso de nuestros productos, servicios o
                    aplicaciones{" "}
                  </li>
                  <li>
                    Ofrecerle asistencia sobre nuestras iniciativas
                    promocionales y de marketing (que puede incluir el envío de
                    contenidos publicitarios basado en sus comportamientos
                    habituales en la red){" "}
                  </li>
                  <li>
                    Ofrecer contenidos de terceros (como contenidos de redes
                    sociales){" "}
                  </li>
                </ul>
                <p>
                Aquí puede ver una lista detallada de las cookies que utilizamos, con su correspondiente descripción. Clasificamos las cookies en las siguientes categorías:
                </p>
                <ul>
                  <li>Cookies estrictamente necesarias</li>
                  <li>Cookies de rendimiento</li>
                  <li>Cookies funcionales</li>
                  <li>Cookies dirigidas</li>
                </ul>
                <p>
                Excepto cuando lo permita la ley aplicable, instalaremos las cookies una vez hayamos recibido su consentimiento mediante el banner de cookies o su centro de preferencias. Podrá modificar la configuración de las cookies en su equipo de acuerdo con su categoría (a excepción de las cookies que sean estrictamente necesarias para el buen funcionamiento del sitio web) en cualquier momento haciendo click en el botón ´configuración de cookies´ que puede ver más abajo:
                </p>
                <p>
                <div>
                  <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Policy</button>
                </div>
                <br/>
              <br/>
              </p>
              <p>
                <div id="ot-sdk-cookie-policy"></div>
                &nbsp;
              </p>
                
                  <h2>Enlaces a otros sitios web</h2>
                
                <p>
                  Este sitio puede contener enlaces o referencias a otros sitios
                  web. Por favor, tenga en cuenta que no controlamos las cookies
                  / tecnologías de rastreo de otros sitios web, y que esta
                  Política de Cookies no se aplica a esos sitios web.
                </p>
                
                  <h2>Cómo ponerse en contacto con nosotros</h2>
                
                <p>
                  Si tiene alguna pregunta, comentarios, o dudas sobre esta
                  Política de Cookies o sobre los medios de información de este
                  sitio web, por favor póngase en contacto con nosotros según le
                  explicamos en nuestra{" "}
                  <a href="/es/legal/politicia-de-privacidad/" target="_blank">
                    Política de Privacidad
                  </a>
                  .{" "}
                </p>
                
                  <h2>Cambios en esta Política de Cookies</h2>
                
                <p>
                  Si se produjeran cambios en nuestra Política de Cookies, la
                  nueva política se publicaría en este sitio web.{" "}
                </p>
                <p>
                  Esta Política de Cookies fue actualizada por última vez el 4
                  de april de 2022.
                </p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
